import { render, staticRenderFns } from "./PlanItem.vue?vue&type=template&id=342a2b88&scoped=true&"
var script = {}
import style0 from "./PlanItem.vue?vue&type=style&index=0&id=342a2b88&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "342a2b88",
  null
  
)

export default component.exports